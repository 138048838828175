import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Controller, useForm } from 'react-hook-form';
import { FormControl } from '@material-ui/core';
import StepHelper from './StepHelper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#e8f0fa',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff646b',
  },
  label: {
    margin: theme.spacing(0, 2, 1),
  },
}));

export default function Step1(props) {
  const classes = useStyles();
  const { handleSubmit, control, register } = useForm();
  const [city, setCity] = useState('');
  const onSubmit = (data) => props.onSubmit(data);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          À propos de vous
        </Typography>
        <StepHelper active={1} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                autoComplete="fname"
                name="firstname"
                control={control}
                variant="outlined"
                required
                fullWidth
                id="firstname"
                label="Votre prénom"
                rules={{ required: true }}
                autoFocus
                defaultValue={process.env.NODE_ENV === 'development' ? 'Cyril' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="lastname"
                label="Votre nom"
                name="lastname"
                control={control}
                autoComplete="lname"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? 'Souillard' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Votre adresse email"
                name="email"
                control={control}
                autoComplete="email"
                rules={{ required: true }}
                type="email"
                defaultValue={process.env.NODE_ENV === 'development' ? 'csouillard@wishibam.com' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Votre numéro de téléphone"
                name="phone"
                type="tel"
                inputProps={{ minLength: 10 }}
                control={control}
                autoComplete="phone"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? '0611893003' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="job"
                label="Votre poste"
                name="job"
                control={control}
                autoComplete="job"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? 'CTO' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" className={classes.label}>Sur quel site souhaitez-vous être affilié?</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  variant="outlined"
                  required
                  fullWidth
                  displayEmpty
                  id="environment"
                  label="environment-label"
                  name="environment"
                  control={control}
                  autoComplete="environment"
                  onChange={(data) => setCity(data.target.value)}
                  rules={{ required: true }}
                >
                  <MenuItem value="Angers">Angers</MenuItem>
                  <MenuItem value="Carpentras">Carpentras</MenuItem>
                  <MenuItem value="Castres">Castres</MenuItem>
                  <MenuItem value="Chaville">Chaville</MenuItem>
                  <MenuItem value="Drancy">Drancy</MenuItem>
                  <MenuItem value="Meaux">Meaux</MenuItem>
                  <MenuItem value="Megeve">Megève</MenuItem>
                  <MenuItem value="Haut de France">Mes emplettes locales ( Haut de France )</MenuItem>
                  <MenuItem value="Namur">Namur</MenuItem>
                  <MenuItem value="Metz">Metz</MenuItem>
                  <MenuItem value="Paris Saclay">Paris - Saclay</MenuItem>
                  <MenuItem value="Shop In Touraine">Shop In Touraine</MenuItem>
                  <MenuItem value="Suresnes">Suresnes</MenuItem>
                  <MenuItem value="Terre de Camargue">Terre de Camargue</MenuItem>
                  <MenuItem value="Versailles">Versailles</MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {
                            city === 'Autre'
                              ? (
                                <Grid item xs={12}>
                                  <Controller
                                    as={TextField}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="city"
                                    label="Votre ville"
                                    name="city"
                                    control={control}
                                    autoComplete="city"
                                    rules={{ required: true }}
                                  />
                                </Grid>
                              )
                              : <></>

                        }
            <input type="hidden" name="city" value={city} ref={register} />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Étape suivante
          </Button>
          <input type="hidden" name="step" value="Step2" ref={register} />
        </form>
      </div>
    </Container>
  );
}
