import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import StepHelper from './StepHelper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff646b',
  },
  text: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Step5(props) {
  const classes = useStyles();
  const { city, data } = props;

  let text = <></>

  if (data.needPhoneCall === 'true' || data.hasQuestions === 'true') {
    text = <p>Un membre de notre équipe reviendra vers vous <b>par téléphone dans les prochaines heures</b> pour répondre à toutes vos questions et entamer le processus d'intégration !</p>
  } else if (data.hasSoftware === false && data.websiteUrl) {
    text = <p>Nous revenons vers vous <b>par email très bientôt</b> pour vous présenter le processus de d’intégration !</p>
  }
  else if (data.hasSoftware === 'true') {
    text = <div>
      <p>Un membre de notre équipe reviendra vers vous <b>par téléphone dans les prochaines heures</b> pour entamer le processus d'intégration !</p>
      <p>Afin d’assurer l’efficacité de ce rendez-vous, nous vous demandons de regarder si vous pouvez télécharger votre base de données produits depuis votre système de caisse.<br/>
        Si vous n’y arrivez pas, pas d’inquiétudes, notre équipe vous accompagnera !</p>
    </div>
  } else if (data.websiteUrl) {
    text = <div>
      <p>Un membre de notre équipe reviendra vers vous par téléphone dans les prochaines heures pour continuer le processus d'intégration !</p>
    </div>
  }


  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Merci pour ces informations !
        </Typography>
        <StepHelper active={4} />
        <div className={classes.text}>
          Nous sommes très heureux de vous compter parmi les commerçants de la plateforme solidaire de
          {' '}{city}.

          {text}
          { console.log(data) }
        </div>
      </div>
    </Container>
  );
}
