import React, {Component} from "react";
import axios from 'axios';
import '../assets/css/App.css';
import { merge } from "immutable";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step0 from "./Steps/Step0";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step3 from "./Steps/Step3";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstname: '',
        lastname: '',
        job: '',
        city: '',
        email: '',
        phone: '',
        shopname: '',
        address: '',
        hours: '',
        sellers: '',
        references: '',
        description: '',
        cms: '',
        websiteUrl: '',
        hasSoftware: '',
        needPhoneCall: '',
        hasQuestions: '',
        sector: '',
      },
    };
    this.mergeData = this.mergeData.bind(this);
    this.chooseStep = this.chooseStep.bind(this);
  }

  mergeData = function(newData) {
    let state = this.state.data;
    let zapierUrl = process.env.REACT_APP_ZAPIER_TRELLO_CARD_CREATE_UPDATE;
    let newState = merge(state, newData);
    this.setState( {data: newState} );
    if(this.state.data.firstname) {
      axios.post(zapierUrl, JSON.stringify(this.state.data));
    }
  }


  chooseStep = () => {
    if(!this.state.data.step) {
      return (<Step0 onSubmit={this.mergeData} />);
    }

    switch (this.state.data.step) {
      case "Step1":
        return (<Step1 onSubmit={this.mergeData} getSteps={this.getSteps}/>);
      case "Step2":
        return (<Step2 onSubmit={this.mergeData} getSteps={this.getSteps}/>);
      case "Step3":
        return (<Step3 onSubmit={this.mergeData} getSteps={this.getSteps}/>);
      case "Step4":
        return (<Step4 onSubmit={this.mergeData} getSteps={this.getSteps}/>);
      default:
        return (<Step5 city={this.state.data.city} data={this.state.data} getSteps={this.getSteps}/>);
    }
  }

  render() {
    return (
        <div className="App">
          {this.chooseStep()}
        </div>
    );
  }
}

export default App;
