import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Controller, useForm } from 'react-hook-form';
import StepHelper from './StepHelper';
import {FormControl} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff646b',
  },
  label: {
    margin: theme.spacing(0, 2, 1),
  },
}));

export default function Step2(props) {
  const classes = useStyles();
  const [sector, setSector] = useState('');
  const { handleSubmit, control, register } = useForm();
  const onSubmit = (data) => props.onSubmit(data);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          À propos de vous
        </Typography>
        <StepHelper active={2} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                autoComplete="shopname"
                name="shopname"
                control={control}
                variant="outlined"
                required
                fullWidth
                id="shopname"
                label="Le nom de votre magasin"
                rules={{ required: true }}
                autoFocus
                defaultValue={process.env.NODE_ENV === 'development' ? 'Cyril Store' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                multiline
                variant="outlined"
                required
                fullWidth
                id="address"
                label="Son adresse (N° et rue, code postal, ville)"
                name="address"
                rows={3}
                control={control}
                autoComplete="address"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? '43 rue du pivert' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                multiline
                variant="outlined"
                required
                fullWidth
                id="hours"
                label="Ses horaires d’ouverture"
                rows={3}
                name="hours"
                control={control}
                autoComplete="hours"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? '24h/24' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="shopphone"
                label="Son numéro de téléphone"
                name="shopphone"
                control={control}
                type="tel"
                inputProps={{ minLength: 10 }}
                autoComplete="shopphone"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? '0611893003' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                id="sellers"
                label="Le nombre de vendeur"
                name="sellers"
                type="number"
                control={control}
                autoComplete="sellers"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? '42' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                  as={TextField}
                  variant="outlined"
                  required
                  fullWidth
                  id="references"
                  label="Le nombre de références en magasin"
                  name="references"
                  type="number"
                  control={control}
                  autoComplete="references"
                  rules={{ required: true }}
                  defaultValue={process.env.NODE_ENV === 'development' ? '42' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" className={classes.label}>Secteur d'activité</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    required
                    fullWidth
                    displayEmpty
                    id="sector"
                    label="sector-label"
                    name="sector"
                    control={control}
                    autoComplete="sector"
                    onChange={(data) => setSector(data.target.value)}
                    rules={{ required: true }}
                >
                  <MenuItem value="Déco Intérieur">Déco Intérieur</MenuItem>
                  <MenuItem value="Prêt-à-porter">Prêt-à-porter</MenuItem>
                  <MenuItem value="Café/Epicerie">Café/Epicerie</MenuItem>
                  <MenuItem value="Loisir/Musique">Loisir/Musique</MenuItem>
                  <MenuItem value="Chaussures">Chaussures</MenuItem>
                  <MenuItem value="Alcool">Alcool</MenuItem>
                  <MenuItem value="Sport">Sport</MenuItem>
                  <MenuItem value="Magasin de Sport Basket">Magasin de Sport Basket</MenuItem>
                  <MenuItem value="Déco& Loisir">Déco& Loisir</MenuItem>
                  <MenuItem value="Beauté">Beauté</MenuItem>
                  <MenuItem value="Fleuriste">Fleuriste</MenuItem>
                  <MenuItem value="Loisirs">Loisirs</MenuItem>
                  <MenuItem value="Décoration">Décoration</MenuItem>
                  <MenuItem value="High-Tech">High-Tech</MenuItem>
                  <MenuItem value="Épicerie">Épicerie</MenuItem>
                  <MenuItem value="Soin coiffure">Soin coiffure</MenuItem>
                  <MenuItem value="Vins">Vins</MenuItem>
                  <MenuItem value="Cuisine">Cuisine</MenuItem>
                  <MenuItem value="Bijoux">Bijoux</MenuItem>
                  <MenuItem value="Livre & DVD">Livre & DVD</MenuItem>
                  <MenuItem value="Boulangerie">Boulangerie</MenuItem>
                  <MenuItem value="PAP bébé">PAP bébé</MenuItem>
                  <MenuItem value="Animalerie">Animalerie</MenuItem>
                  <MenuItem value="Epicerie">Epicerie</MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                  <MenuItem value="Photo">Photo</MenuItem>
                </Select>
              </FormControl>
              <input type="hidden" name="sector" value={sector} ref={register} />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                variant="outlined"
                required
                fullWidth
                multiline
                rows={4}
                id="description"
                label="Description"
                placeholder="Un petit mot qui donnera envie aux clients d’acheter chez vous (qualité des produits, gentillesse des équipes, ….)"
                name="description"
                control={control}
                autoComplete="description"
                rules={{ required: true }}
                defaultValue={process.env.NODE_ENV === 'development' ? 'le gras c\'est la vie' : ''}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Étape suivante
          </Button>
          <input type="hidden" name="step" value="Step3" ref={register} />
        </form>
      </div>
    </Container>
  );
}
