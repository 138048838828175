import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Controller, useForm } from 'react-hook-form';
import { FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import StepHelper from './StepHelper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
  },
  label: {
    margin: theme.spacing(0, 2, 1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff646b',
  },
}));

export default function Step4(props) {
  const classes = useStyles();
  const { handleSubmit, control, register } = useForm();
  const onSubmit = (data) => props.onSubmit(data);
  const [hasWebsite, setHasWebsite] = useState('');
  const [hasSoftware, setHasSoftware] = useState('');
  const [cms, setCms] = useState('');
  const [yesWebsiteButtonBackground, setYesWebsiteButtonBackground] = useState('');
  const [noWebsiteButtonBackground, setNoWebsiteButtonBackground] = useState('');
  const [yesSoftwareButtonBackground, setYesSoftwareButtonBackground] = useState('');
  const [noSoftwareButtonBackground, setNoSoftwareButtonBackground] = useState('');

  function handleClickWebsite(e) {
    if (e.currentTarget.value === '1') {
      setHasWebsite(true);
      setYesWebsiteButtonBackground('primary');
      setNoWebsiteButtonBackground('default');
    } else {
      setHasWebsite(false);
      setYesWebsiteButtonBackground('default');
      setNoWebsiteButtonBackground('primary');
    }
  }

  function handleClickSoftware(e) {
    if (e.currentTarget.value === '1') {
      setHasSoftware(true);
      setYesSoftwareButtonBackground('primary');
      setNoSoftwareButtonBackground('default');
    } else {
      setHasSoftware(false);
      setYesSoftwareButtonBackground('default');
      setNoSoftwareButtonBackground('primary');
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Intégration de vos produits
        </Typography>
        <StepHelper active={3} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Avez-vous un site internet marchand ?
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                value="1"
                color={yesWebsiteButtonBackground}
                onClick={handleClickWebsite}
              >
                Oui
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                value="0"
                color={noWebsiteButtonBackground}
                onClick={handleClickWebsite}
              >
                Non
              </Button>
            </Grid>
            {
                            hasWebsite
                              ? (
                                <>
                                  <Grid item xs={12}>
                                    <Controller
                                      as={TextField}
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="websiteUrl"
                                      label="lien de votre site internet"
                                      name="websiteUrl"
                                      type="url"
                                      control={control}
                                      autoComplete="websiteUrl"
                                      rules={{ required: true }}
                                      defaultValue={process.env.NODE_ENV === 'development' ? 'https://www.cyril.com' : ''}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormControl className={classes.formControl}>
                                      <InputLabel id="demo-simple-select-label" className={classes.label}>
                                        Quelle
                                        solution utilisez-vous ?
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        displayEmpty
                                        id="cms"
                                        label="cms-label"
                                        control={control}
                                        autoComplete="cms"
                                        rules={{ required: true }}
                                        onChange={(data) => setCms(data.target.value)}
                                        ref={register}
                                      >
                                        <MenuItem value="Prestashop">Prestashop</MenuItem>
                                        <MenuItem value="Shopify">Shopify</MenuItem>
                                        <MenuItem value="Wix">Wix</MenuItem>
                                        <MenuItem value="Magento">Magento</MenuItem>
                                        <MenuItem value="Woocommerce">Woocommerce</MenuItem>
                                        <MenuItem value="Je ne sais pas">Je ne sais pas</MenuItem>
                                        <MenuItem value="Autre">Autre</MenuItem>
                                      </Select>
                                    </FormControl>

                                    <input type="hidden" name="cms" value={cms} ref={register} />

                                    <Button
                                      type="submit"
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      className={classes.submit}
                                    >
                                      Étape suivante
                                    </Button>
                                  </Grid>
                                </>
                              )

                              : hasWebsite === false ? (
                                <>
                                  <Grid item xs={12}>
                                    Avez-vous un système de gestion de
                                    stock dans lequel les références sont
                                    enregistrées ?
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button
                                      variant="contained"
                                      value="1"
                                      color={yesSoftwareButtonBackground}
                                      onClick={handleClickSoftware}
                                    >
                                      Oui
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button
                                      variant="contained"
                                      value="0"
                                      color={noSoftwareButtonBackground}
                                      onClick={handleClickSoftware}
                                    >
                                      Non
                                    </Button>
                                  </Grid>
                                </>
                              )
                                : <div />
                        }

            {
                            ((hasSoftware === true || hasSoftware === false) && !hasWebsite)
                              ? (
                                <Grid item xs={12}>
                                  <input type="hidden" name="hasSoftware" value={hasSoftware} ref={register} />
                                  <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                  >
                                    Étape suivante
                                  </Button>
                                </Grid>
                              )
                              : <div />

                        }

          </Grid>

          <input type="hidden" name="step" value="Step5" ref={register} />
        </form>
      </div>
    </Container>
  );
}
