import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import StepHelper from './StepHelper';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#e8f0fa',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff646b',
  },
  label: {
    margin: theme.spacing(0, 2, 1),
  },
}));

export default function Step1(props) {
  const classes = useStyles();
  const { handleSubmit, register } = useForm();
  const [nextStep, setNextStep] = useState('Step3');
  const onSubmit = (data) => props.onSubmit(data);
  const [hasPresentation, setHasPresentation] = useState('');
  const [yesPresentationButtonBackground, setYesPresentationButtonBackground] = useState('');
  const [noPresentationButtonBackground, setNoPresentationButtonBackground] = useState('');
  const [hasQuestion, setHasQuestion] = useState('');
  const [yesQuestionButtonBackground, setYesQuestionButtonBackground] = useState('');
  const [noQuestionButtonBackground, setNoQuestionButtonBackground] = useState('');

  function handleClickPresentation(e) {
    if (e.currentTarget.value === '1') {
      setHasPresentation(true);
      setYesPresentationButtonBackground('primary');
      setNoPresentationButtonBackground('default');
    } else {
      setHasPresentation(false);
      setYesPresentationButtonBackground('default');
      setNoPresentationButtonBackground('primary');
      setNextStep('Step5');
    }
  }

  function handleClickQuestion(e) {
    if (e.currentTarget.value === '1') {
      setHasQuestion(true);
      setYesQuestionButtonBackground('primary');
      setNoQuestionButtonBackground('default');
      setNextStep('Step5');
    } else {
      setHasQuestion(false);
      setYesQuestionButtonBackground('default');
      setNoQuestionButtonBackground('primary');
      setNextStep('Step4');
    }
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <StepHelper active={3} />
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Vous a-t-on déjà présenté le projet ?
            </Grid>
            <Grid item xs={6}>
              <Button
                  variant="contained"
                  value="1"
                  color={yesPresentationButtonBackground}
                  onClick={handleClickPresentation}
              >
                Oui
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                  variant="contained"
                  value="0"
                  color={noPresentationButtonBackground}
                  onClick={handleClickPresentation}
              >
                Non
              </Button>
            </Grid>
          </Grid>
          { hasPresentation !== '' && hasPresentation !== false ?
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Avez-vous des questions supplémentaires quant au fonctionnement de la plateforme ou souhaitez-vous débuter votre intégration dés maintenant ?
                </Grid>
                <Grid item xs={6}>
                  <Button
                      variant="contained"
                      value="1"
                      color={yesQuestionButtonBackground}
                      onClick={handleClickQuestion}
                  >
                    J'ai des questions supplémentaires
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                      variant="contained"
                      value="0"
                      color={noQuestionButtonBackground}
                      onClick={handleClickQuestion}
                  >
                    Je souhaite débuter mon intégration
                  </Button>
                </Grid>
              </Grid> : <></>

          }
          { (hasQuestion !== '' || hasPresentation === false ) ?
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Étape suivante
          </Button> :
              <></>
          }
          <input type="hidden" name="needPhoneCall" value={hasPresentation?false:true} ref={register} />
          <input type="hidden" name="hasQuestions" value={hasQuestion} ref={register} />
          <input type="hidden" name="step" value={nextStep} ref={register} />
        </form>
      </div>
    </Container>
  );
}
