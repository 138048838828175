import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {makeStyles} from "@material-ui/core/styles";

export default function StepHelper(props) {
  function getSteps() {
    return ['À propos de vous', 'À propos de votre magasin', 'À propos de votre stock', 'C\'est parti !'];
  }

  const useStyles = makeStyles((theme) => ({
    stepper: {
      backgroundColor: '#e8f0fa',
    },
  }));

  const steps = getSteps();
  const classes = useStyles();

  return (
    <Stepper alternativeLabel activeStep={props.active - 1} className={classes.stepper}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
