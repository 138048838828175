import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#e8f0fa',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#ff646b',
  },
  text: {
    margin: theme.spacing(3, 0, 2),
  },
  h1: {
    fontSize: '2em',
  },
  li : {
    textAlign: 'left',
  },
  rose: {
    color: '#c43355',
    fontWeight: 'bold',
  }
}));

export default function Step0(props) {
  const classes = useStyles();
  const { handleSubmit, register } = useForm();
  const onSubmit = (data) => props.onSubmit(data);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Typography component="h1" variant="h3">
            Bienvenue
          </Typography>
          <ul>
            <li className={classes.li}>Nous sommes issus de familles de commerçants</li>
            <li className={classes.li}>Nous croyons en une technologie utile et raisonnable</li>
            <li className={classes.li}>Nous sommes une équipe de passionnés, ambitieux et agiles</li>
            <li className={classes.li}>Nous voulons des villes et des quartiers vivants</li>
            <li className={classes.li}>Nous nous battons pour que le commerce physique reste un pilier de notre économie</li>
          </ul>
          <Typography component="h1" variant="h4" className={classes.h1}>
            Notre objectif
          </Typography>
          <p>
            Vous permettre de vendre en ligne les produits disponibles
            dans votre magasin pour vous permettre de générer du <b>chiffre d’affaires additionnel</b>.
          </p>
          <input type="hidden" name="step" value="Step1" ref={register} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Étape suivante
          </Button>
        </form>
      </div>
    </Container>
  );
}
